import Vue from 'vue'
import App from '../components/pub/CourseButton.vue'
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.use(VueSweetalert2);

axios.defaults.headers.common['x-api-key'] = '9c6334a7-8215-46ff-8ec9-f442cdc43cac';
Vue.prototype.$axios = axios;

const elements = document.querySelectorAll(`div[id*='course_']`)

elements.forEach( (element) => {
    let course_slug = element?.id?.substring(7)
    document.addEventListener('DOMContentLoaded', () => {
        const app = new Vue({
            render: h => h(App, { props: { course_slug } })
        }).$mount('#' + element.id)
    })
})
